function resizeWindow() {
  var height = document.body.scrollHeight + 250;
  var width = 650;

  window.resizeTo(width, height);
}

document.addEventListener("turbolinks:load", () => {
  const element = document.getElementById("assessment-form");
  if (element != null) {
    let contentPlaceholder = element.dataset.content;
    let signaturePlaceholder = element.dataset.signature;

    // document.addEventListener("trix-initialize", (event) => {
    //   const elem = event.target;
    //   const tagName = elem.tagName;
    //   if (tagName && tagName.toLowerCase() == 'trix-editor') {
    //     inputAttr = elem.getAttribute('input')

    //     if (inputAttr == 'message_content') {
    //       elem.editor.loadHTML(contentPlaceholder);
    //     }
    //     if (inputAttr == 'message_signature') {
    //       elem.editor.loadHTML(signaturePlaceholder);
    //     }
    //   }
    // });

    let contentEditorElement = document.querySelector("#assessment-form trix-editor[input='message_content']");
    contentEditorElement.editor.loadHTML(contentPlaceholder);

    let signatureEditorElement = document.querySelector("#assessment-form trix-editor[input='message_signature']");
    signatureEditorElement.editor.loadHTML(signaturePlaceholder);

    resizeWindow();
  }
});
